<template>
    <div class="modal fade show d-block" v-if="shareURL">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-0 bg-white pb-0">
                    <p class="mb-0 f-20 fw-300">{{$t('leader_profile.share')}}</p>
                    <button type="button" class="btn-close" @click="close()"></button>
                </div>
                <div class="modal-body">
                    <ul class="shareModal-social d-flex align-items-center flex-wrap">
                        <li><a class="facebook" target="_blank" :href="'//www.facebook.com/sharer/sharer.php?u='+shareSocialURL()"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                        <li><a class="twitter" target="_blank" :href="'//twitter.com/intent/tweet?url='+shareSocialURL()"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                        <li><a class="linkedin" target="_blank" :href="'//www.linkedin.com/shareArticle?mini=true&url='+shareSocialURL()"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                        <li><a class="pinterest" target="_blank" :href="'//pinterest.com/pin/create/link/?url='+shareSocialURL()"><i class="fa fa-pinterest-p" aria-hidden="true"></i></a></li>
                        <li><a class="reddit" target="_blank" :href="'//www.reddit.com/submit?url='+shareSocialURL()"><i class="fa fa-reddit-alien" aria-hidden="true"></i></a></li>
                        <li><a class="thumblr" target="_blank" :href="'//www.tumblr.com/share/link?url='+shareSocialURL()"><v-lazy-image src="/assets/images/thumblr.svg" :alt="$t('leader_profile.text1')" :title="$t('leader_profile.text1')" /></a></li>
                        <!-- <li><a class="mix" target="_blank" href="//mix.com/"><v-lazy-image src="/assets/images/mix.svg" alt="Mix" title="Mix"/></a></li> -->
                        <li><a class="oauth" target="_blank" :href="'//vk.com/share.php?url='+shareSocialURL()"><v-lazy-image src="/assets/images/oauth.svg" :alt="$t('leader_profile.text2')" :title="$t('leader_profile.text2')" /></a></li>
                        <li><a class="telegram" target="_blank" :href="'//t.me/share/url?url='+shareSocialURL()"><v-lazy-image src="/assets/images/telegram.svg" :alt="$t('leader_profile.text3')" :title="$t('leader_profile.text3')" /></a></li>
                        <!-- <li><a class="messanger" target="_blank" :href="'fb-messenger://share?text='+shareSocialURL()"><v-lazy-image src="/assets/images/messanger.svg" alt="messanger" title="messanger" /></a></li> -->
                        <li><a class="whatsapp" target="_blank" :href="'whatsapp://send?text='+shareSocialURL()"><i class="fa fa-whatsapp" aria-hidden="true"></i></a></li>
                        <li><a class="xing" target="_blank" :href="'//www.xing.com/spi/shares/new?url='+shareSocialURL()"><i class="fa fa-xing" aria-hidden="true"></i></a></li>
                        <li><a class="line" target="_blank" :href="'//social-plugins.line.me/lineit/share?url='+shareSocialURL()"><v-lazy-image src="/assets/images/line.svg" :alt="$t('leader_profile.text4')" :title="$t('leader_profile.text4')" /></a></li>
                        <li><a class="sms" target="_blank" :href="'sms:?body='+shareSocialURL()"><v-lazy-image src="/assets/images/sms.svg" :alt="$t('leader_profile.text6')" :title="$t('leader_profile.text6')" /></a></li>
                        <!-- <li><a class="email" target="_blank" :href="'mailto:?body='+shareSocialURL()"><i class="fa fa-envelope" aria-hidden="true"></i></a></li> -->
                        <li><button class="copy" type="button" v-clipboard:copy="shareSocialURL()" v-clipboard:success="onCopy" v-clipboard:error="onError"><v-lazy-image src="/assets/images/copy.svg" :alt="$t('leader_profile.text5')" :title="$t('leader_profile.text5')" /></button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { useToast } from "vue-toastification";
    export default {
        setup() {
            const toast = useToast();
            return { toast }
        },
        props: ["shareURL","shareType","close"],
        data(){
            return {
                urlVar: '',
            }
        },
        methods: {
            onCopy(){
                this.toast.success(this.$t('leader_profile.linkCopied'));
            },
            onError(){
                this.toast.error(this.$t('leader_profile.linkFailed'));
            },
            shareSocialURL(){
                if(this.shareType){
                    if(this.shareType=='leaderProfile'){
                        return `${this.static_vars.domainURL}trader/${this.shareURL}/trading?t=10000&m=1`
                    } else if(this.shareType=='socialFeed'){
                        return `${this.static_vars.domainURL}newsfeed/share?eventId=${this.shareURL}`
                    }
                }
            },
        },
    };
</script>